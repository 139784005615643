@font-face {
  font-family: 'icomoon';
  src:  url('custom-fonts/icomoon.eot?s9pbw0');
  src:  url('custom-fonts/icomoon.eot?s9pbw0#iefix') format('embedded-opentype'),
    url('custom-fonts/icomoon.ttf?s9pbw0') format('truetype'),
    url('custom-fonts/icomoon.woff?s9pbw0') format('woff'),
    url('custom-fonts/icomoon.svg?s9pbw0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Help:before {
  content: "\e900";
}
.icon-add-square:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-calendar-edit:before {
  content: "\e903";
}
.icon-close-square:before {
  content: "\e904";
}
.icon-danger:before {
  content: "\e905";
}
.icon-home:before {
  content: "\e906";
}
.icon-minus-square:before {
  content: "\e907";
}
.icon-notification:before {
  content: "\e908";
}
.icon-setting-2:before {
  content: "\e909";
}
.icon-setting-3:before {
  content: "\e90a";
}
.icon-task-square:before {
  content: "\e90b";
}
.icon-tick-circle:before {
  content: "\e90c";
}
.icon-tick-square:before {
  content: "\e90d";
}
.icon-timer:before {
  content: "\e90e";
}
.icon-arrow-square-down:before {
  content: "\e90f";
}
.icon-arrow-square-left:before {
  content: "\e910";
}
.icon-arrow-square-right:before {
  content: "\e911";
}
.icon-arrow-square-up:before {
  content: "\e912";
}
.icon-attach-square:before {
  content: "\e913";
}
.icon-connect:before {
  content: "\e914";
}
.icon-direct-inbox:before {
  content: "\e915";
}
.icon-direct-send:before {
  content: "\e916";
}
.icon-document-normal:before {
  content: "\e917";
}
.icon-dots:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e919";
}
.icon-eye:before {
  content: "\e91a";
}
.icon-eye-slash:before {
  content: "\e91b";
}
.icon-maximize-3:before {
  content: "\e91c";
}
.icon-message-text:before {
  content: "\e91d";
}
.icon-modules:before {
  content: "\e91e";
}
.icon-note-2:before {
  content: "\e91f";
}
.icon-trash:before {
  content: "\e920";
}
.icon-user-square:before {
  content: "\e921";
}
.icon-archive-book:before {
  content: "\e922";
}
.icon-save:before {
  content: "\e923";
}